const state = () => ({
  notifications: {
    unread: [],
    read: [],
  },
})

const getters = {
  allNotifications: (state) => [
    ...state.notifications.unread,
    ...state.notifications.read,
  ],
  unreadNotifications: (state) => state.notifications.unread,
  readNotifications: (state) => state.notifications.read,
}

const actions = {
  fetchNotifications({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get('/notifications/', { headers })
      .then((response) => {
        commit('setNotifications', response.data)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },

  markAllAsRead({ dispatch }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .post('/notifications/mark_all_read/', null, { headers })
      .then(() => {
        return dispatch('fetchNotifications')
      })
  },

  deleteAllNotifications({ dispatch }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .delete('/notifications/delete_all_read/', { headers })
      .then(() => {
        return dispatch('fetchNotifications')
      })
  },

  markAsRead({ dispatch }, notificationId) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .put(`/notifications/${notificationId}/`, null, { headers })
      .then(() => {
        return dispatch('fetchNotifications')
      })
      .catch((error) => {
        console.error(error)
      })
  },

  deleteNotification({ dispatch }, notificationId) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .delete(`/notifications/${notificationId}/`, { headers })
      .then(() => {
        return dispatch('fetchNotifications')
      })
  },
}

const mutations = {
  setNotifications: (state, notifications) =>
    (state.notifications = notifications),
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
